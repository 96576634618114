import { translate } from 'magner';
// login
import loginPageConfig from 'features/login/login';
import milliardLoginConfig from 'features/login/milliard';
// residents
import residentsConfig from 'features/residents/residents/hubstr';
import residentConfig from 'features/residents/resident/meta-hubstr';
import udSchoolResidentsConfig from 'features/residents/residents/udschool';
// companies
import companiesConfig from 'features/residents/companies/companies/hubstr';
import hubstrCompanyConfig from 'features/residents/companies/company/hubstr';
import cloverCompanyConfig from 'features/residents/companies/company/clover';
import milliardCompanyConfig from 'features/residents/companies/company/milliard';
// events
import eventConfig from 'features/events/event/hubstr';
import eventGuestsConfig from 'features/events/guests/guests/hubstr';
import eventsConfig from 'features/events/events/hubstr';
// addresses
import addressConfig from 'features/addresses/address/hubstr';
import addressesConfig from 'features/addresses/addresses/hubstr';
// cabinet-sections
import cabinetSectionsConfig from 'features/settings/cabinet-sections/sections/common';
import deepLinksSectionConfig from 'features/settings/cabinet-sections/deep-links/links/common';
// reports
import reportsUserActivityConfig from 'features/reports/user-activity/table';
import reportsEventsConfig from 'features/reports/events/table';
// link-groups
import linkGroupsConfig from 'features/settings/link-groups/groups/hubstr';
import linksConfig from 'features/settings/link-groups/links/hubstr';
// moderation
import complaintsConfig from 'features/settings/complaints/complaints/hubstr';
import bansConfig from 'features/settings/bans/bans/hubstr';
// other
import eventCategoriesConfig from 'features/settings/event-categories/categories/hubstr';
import clubRolesConfig from 'features/settings/club-roles/roles/hubstr';
import mobileEditorConfig from 'features/settings/mobile-editor/mobile-editor';
import authListConfig from 'features/settings/authorization/list';
import preferenceConfig from 'features/settings/preferences/table/hubstr';
import changeLogConfig from 'features/change-log/common/common';
import appearanceConfig from 'features/settings/appearance/appearance';

import LogoNotCollapsed from 'features/common/logo-not-collapsed.vue';
import LogoCollapsed from 'features/common/logo-collapsed.vue';
import notificationTableConfig from 'features/settings/notification/list/hubstr';
import associationConfig from 'features/residents/companies/company/meta-hubstr';
import onboardingEditorConfig from 'features/settings/onboarding-editor/onboarding-editor';
import fieldsEditorConfig from 'features/settings/editor/residents-editor/editor';
import interviewsConfig from 'features/settings/interviews/interviews';
import interviewConfig from 'features/settings/interviews/interview';
import associationEditorConfig from 'features/settings/editor/association-editor/association-editor';
import {
  APP_CLOVER, APP_MILLIARD, APP_SETTINGS_EDITOR_ON, APP_UDSCHOOL, SUB_DOMEN,
} from '~/constants';

// TODO пока нет редактора полей компаний
const switchCompanyConfig = () => {
  switch (SUB_DOMEN) {
    case APP_MILLIARD:
      return milliardCompanyConfig;
    case APP_CLOVER:
      return cloverCompanyConfig;
    default:
      return associationConfig;
  }
};

// TODO пока нет маски телефона
const switchLoginConfig = () => {
  switch (SUB_DOMEN) {
    case APP_MILLIARD:
      return milliardLoginConfig;
    default:
      return loginPageConfig;
  }
};

// TODO UD просто особенный
const switchResidentsConfig = () => {
  switch (SUB_DOMEN) {
    case APP_UDSCHOOL:
      return udSchoolResidentsConfig;
    default:
      return residentsConfig;
  }
};

export const commonRouterConfig = {
  global: {
    suffixUrl: 'admin',
    homeNoAuthName: 'login',
    homeHasAuthName: 'residents',
  },

  routes: [
    {
      type: 'custom',
      route: {
        name: 'vimeo',
        path: '/domen/video/:id',
        component: () => import('features/vimeo/vimeo.vue'),
        roles: false,
      },
    },
    {
      type: 'custom',
      route: {
        name: 'home',
        path: '/',
        component: () => import('configs/empty.vue'),
        roles: false,
      },
    },

    {
      type: 'preset',
      route: {
        path: '/login',
        name: 'login',
      },
      preset: {
        preset: 'login',
        config: switchLoginConfig(),
      },
    },

    {
      type: 'layout',
      layout: {
        layout: 'main',
        name: '',
        path: '/',
        props: {
          headerIcon: () => LogoNotCollapsed,
          headerCollapsedIcon: () => LogoCollapsed,

          sidebarGroups: [
            {
              name: 'catalogs-group',
              icon: () => import('assets/icons/book.svg'),
              title: translate('hubstr.login.sidebar.group_title'),
              routes: [
                'event-categories',
                'addresses',
                'club-roles',
                'cabinet-sections',
              ],
            },
            {
              name: 'system-group',
              icon: () => import('assets/icons/settings.svg'),
              title: translate('hubstr.system.sidebar.group_title'),
              routes: [
                'mobile-editor',
                'onboarding-editor',
                'link-groups',
                'auth-list',
                'preferences',
                'appearance',
                'notifications',
              ],
            },
            {
              name: 'moderation-group',
              icon: () => import('assets/icons/user.svg'),
              title: translate('hubstr.moderation.sidebar.group_title'),
              routes: [
                'complaints',
                'bans',
              ],
            },
            {
              name: 'reports-group',
              icon: () => import('assets/icons/reports.svg'),
              title: translate('hubstr.reports.sidebar.title'),
              routes: [
                'report-user-activity',
                'report-events',
              ],
            },
          ],
        },
        routes: [
          /** Residents - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: switchResidentsConfig(),
            },
            route: {
              name: 'residents',
              path: '/residents',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/users.svg'),
              title: translate('hubstr.resident.sidebar.table_title'),
            },
          },
          /** Residents - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: residentConfig,
            },
            route: {
              name: 'resident',
              path: '/residents/:id',
              link: '/residents/new',
              roles: true,
              visible: false,
              icon: () => import('assets/icons/user.svg'),
              title: translate('hubstr.resident.sidebar.card_title'),
            },
          },
          /** Events - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventsConfig,
            },
            route: {
              name: 'events',
              path: '/events',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/map-pin.svg'),
              title: translate('hubstr.event.sidebar.table_title'),
            },
          },
          /** Events - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: eventConfig,
            },
            route: {
              name: 'event',
              path: '/events/:id',
              link: '/events/new',
              roles: true,
              visible: false,
              title: translate('hubstr.event.sidebar.card_title'),
            },
          },
          /** Events - guests */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventGuestsConfig,
            },
            route: {
              name: 'event-guests',
              path: '/events/:id/guests',
              roles: true,
              visible: false,
              title: translate('hubstr.event.guests.sidebar.table_title'),
            },
          },

          /** Interviews - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: interviewsConfig,
            },
            route: {
              name: 'interviews',
              path: '/settings/interviews',
              roles: true,
              visible: true,
              icon: () => import('assets/icons/polls.svg'),
              title: translate('gaz.interview.sidebar.table_title'),
            },
          },
          /** Interviews - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: interviewConfig,
            },
            route: {
              name: 'interview',
              path: '/settings/interviews/:id',
              link: '/settings/interviews/new',
              roles: true,
              visible: false,
              title: translate('gaz.interview.sidebar.card_title'),
            },
          },

          /** Catalogs group */
          /** Event categories */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: eventCategoriesConfig,
            },
            route: {
              name: 'event-categories',
              path: '/catalogs/event-categories',
              roles: true,
              visible: true,
              title: translate('hubstr.event_category.sidebar.table_title'),
            },
          },
          /** Addresses - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: addressesConfig,
            },
            route: {
              name: 'addresses',
              path: '/catalogs/addresses',
              roles: true,
              visible: true,
              title: translate('hubstr.address.sidebar.table_title'),
            },
          },
          /** Addresses - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: addressConfig,
            },
            route: {
              name: 'address',
              path: '/catalogs/addresses/:id',
              link: '/catalogs/addresses/new',
              roles: true,
              visible: false,
              title: translate('hubstr.address.sidebar.card_title'),
            },
          },
          /** Club roles */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: clubRolesConfig,
            },
            route: {
              name: 'club-roles',
              path: '/catalogs/club-roles',
              roles: true,
              visible: true,
              title: translate('hubstr.club_role.sidebar.table_title'),
            },
          },
          /** Сabinet sections */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: cabinetSectionsConfig,
            },
            route: {
              name: 'cabinet-sections',
              path: '/catalogs/cabinet-sections',
              roles: true,
              visible: true,
              title: translate('hubstr.cabinet_sections.sidebar.title'),
            },
          },
          /** Cabinet Section - deep links */

          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: deepLinksSectionConfig,
            },
            route: {
              name: 'deep_links_sections',
              path: '/catalogs/cabinet-sections/:id',
              roles: true,
              visible: false,
              title: translate('hubstr.cabinet_sections.deep_links.sidebar.title'),
            },
          },
          /** Reports block */
          /** Reports - user activity */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: reportsUserActivityConfig,
            },
            route: {
              name: 'report-user-activity',
              path: '/reports/user-activity',
              roles: true,
              visible: true,
              title: translate('hubstr.reports.sidebar.user_activity_title'),
            },
          },
          /** Reports - events */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: reportsEventsConfig,
            },
            route: {
              name: 'report-events',
              path: '/reports/events',
              roles: true,
              visible: true,
              title: translate('hubstr.reports.sidebar.events_title'),
            },
          },

          /** System group */
          /** Edit mobile profile */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: fieldsEditorConfig,
            },
            route: {
              name: 'mobile-editor',
              path: '/settings/mobile-editor',
              roles: true,
              visible: APP_SETTINGS_EDITOR_ON,
              title: translate('hubstr.mobile_profile.sidebar.title'),
            },
          },
          /** Edit mobile profile V1 */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: mobileEditorConfig,
            },
            route: {
              name: 'mobile-editor-v1',
              path: '/settings/mobile-editor-v1',
              roles: true,
              visible: false,
              title: translate('hubstr.mobile_profile.sidebar.title'),
            },
          },
          /** Associations editor - card */
          /** Onboarding Editor */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: associationEditorConfig,
            },
            route: {
              name: 'associations-editor',
              path: '/admin/settings/associations-editor',
              roles: true,
              visible: false,
              title: 'Ассоциации',
            },
          },
          /** Onboarding Editor */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: onboardingEditorConfig,
            },
            route: {
              name: 'onboarding-editor',
              path: '/admin/settings/onboarding-editor',
              roles: true,
              visible: true,
              title: translate('hubstr.onboarding_editor.sidebar.title'),
            },
          },
          /** Link groups - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: linkGroupsConfig,
            },
            route: {
              name: 'link-groups',
              path: '/settings/link-groups',
              roles: true,
              visible: true,
              title: translate('hubstr.link_groups.sidebar.table_title'),
            },
          },
          /** Links - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: linksConfig,
            },
            route: {
              name: 'links',
              path: '/settings/links/:id',
              roles: true,
              visible: false,
              title: translate('hubstr.link_groups.sidebar.table_title'),
            },
          },
          /** Auth list - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: authListConfig,
            },
            route: {
              name: 'auth-list',
              path: '/settings/auth-list',
              roles: true,
              visible: true,
              title: translate('hubstr.auth.sidebar.table_title'),
            },
          },
          /** Preferences - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: preferenceConfig,
            },
            route: {
              name: 'preferences',
              path: '/settings/preferences',
              roles: true,
              visible: true,
              title: translate('hubstr.preferences.sidebar.title'),
            },
          },
          /** Appearance */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: appearanceConfig,
            },
            route: {
              name: 'appearance',
              path: '/settings/appearance',
              roles: true,
              visible: true,
              title: translate('hubstr.appearance.sidebar.title'),
            },
          },
          /** Notifications */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: notificationTableConfig,
            },
            route: {
              name: 'notifications',
              path: '/settings/notifications',
              roles: true,
              visible: true,
              title: translate('hubstr.notification.sidebar.table_title'),
            },
          },

          /** Moderation group */
          /** Complaints - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: complaintsConfig,
            },
            route: {
              name: 'complaints',
              path: '/moderation/complaints',
              roles: true,
              visible: true,
              title: translate('hubstr.complaints.sidebar.table_title'),
            },
          },
          /** Bans - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: bansConfig,
            },
            route: {
              name: 'bans',
              path: '/moderation/bans',
              roles: true,
              visible: true,
              title: translate('hubstr.bans.sidebar.table_title'),
            },
          },

          /** Companies - table */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: companiesConfig,
            },
            route: {
              name: 'resident-companies',
              path: '/residents/:id/companies',
              roles: true,
              visible: false,
              title: translate('hubstr.resident.sidebar.companies_title'),
            },
          },
          /** Companies - card */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: switchCompanyConfig(),
            },
            route: {
              name: 'company',
              path: '/companies/:id',
              roles: true,
              visible: false,
              title: translate('hubstr.resident.sidebar.company_title'),
            },
          },
          /** Change Log */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: changeLogConfig,
            },
            route: {
              name: 'change-log',
              path: '/change-log',
              roles: true,
              visible: false,
              title: 'change-log',
            },
          },
        ],
      },
    },

    {
      type: 'preset',
      route: {
        path: '/:pathMatch(.*)*',
        name: 'error',
      },
      preset: {
        preset: '404',
        config: {},
      },
    },
  ],
};
