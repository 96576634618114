import type { EventGuest } from 'features/events/guests/types/hubstr';
import { translate, useTranslate } from 'magner';
import { TableColumn } from 'magner/lib/types/components/table';
import Crown from 'features/settings/mobile-editor/components/right-side/assets/icons/crown.svg?inline';
import { formatDate, formatPhone } from '~/utils/format-date';

const statusFormatter = (status: EventGuest['status']): string => {
  const { customT } = useTranslate();

  return customT(translate(`hubstr.event.guests.statuses.${status}`));
};

export const titleIconColumn: TableColumn<EventGuest> = {
  prop: 'titleIcon',
  width: 50,
  align: 'center',
  view: {
    type: 'html',
    formatter: (_, row) => (row.titleIcon ? `<img src=${Crown} alt="crown">` : ''),
  },
};

export const firstNameField:TableColumn<EventGuest> = {
  prop: 'firstName',
  label: translate('hubstr.event.guests.table.name'),
  width: 150,
};

export const lastNameField:TableColumn<EventGuest> = {
  prop: 'lastName',
  label: translate('hubstr.event.guests.table.surname'),
  width: 150,
};

export const emailField:TableColumn<EventGuest> = {
  prop: 'email',
  label: translate('hubstr.event.guests.table.email'),
  width: 250,
};

export const phoneField:TableColumn<EventGuest> = {
  prop: 'phone',
  label: translate('hubstr.event.guests.table.phone'),
  width: 170,
  view: {
    type: 'text',
    formatter: (_, row) => formatPhone(row.phone),
  },
};

export const telegramField:TableColumn<EventGuest> = {
  prop: 'telegram',
  label: translate('hubstr.event.guests.table.telegram'),
};

export const statusField:TableColumn<EventGuest> = {
  prop: 'status',
  label: translate('hubstr.event.guests.table.status'),
  view: {
    type: 'tags',
    formatter: (cell) => statusFormatter(cell as EventGuest['status']),
  },
};

export const updatedField:TableColumn<EventGuest> = {
  prop: 'updatedAt',
  label: translate('hubstr.event.guests.table.updatedAt'),
  view: {
    type: 'text',
    formatter: (_, row) => formatDate(row.updatedAt),
  },
};
