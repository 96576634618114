import {
  accessField,
  addressField, broadcastLinkField,
  categoriesField,
  dateStartField,
  descriptionField,
  invitedDepartmentsRequiredField,
  isRegistrationOpenedField,
  logoField,
  nameField,
  organizersField, registrationDateStartField, reportField, residentsLimitField,
  statusField,
  typeField,
} from 'features/events/layouts/general';
import { translate } from 'magner';
import { getPollList } from 'features/events/requests/hubstr';
import PollLink from 'features/events/layouts/pollLink.vue';

export const hubstrLayout = [
  /** Left column */
  {
    type: 'column',
    props: {
      span: 12,
      xs: 24,
      styles: { 'padding-right': '12px' },
    },
    layout: [
      {
        type: 'row',
        props: {
          elementsGrow: true,
        },
        fields: [nameField],
      },

      /** dateStart-dateEnd in one field */
      {
        type: 'row',
        props: {
          elementsGrow: true,
          class: 'date-picker-row',
        },
        fields: [dateStartField],
      },

      {
        type: 'column',
        props: {
          isPaper: true,
        },
        layout: [
          {
            type: 'column',
            props: {},
            fields: [organizersField],
          },
        ],
      },

      {
        type: 'column',
        props: {
          span: 24,
        },
        fields: [descriptionField, logoField],
      },
    ],
  },

  /** Right column */
  {
    type: 'column',
    props: {
      span: 12,
      xs: 24,
      styles: { 'padding-left': '12px' },
    },
    layout: [
      {
        type: 'column',
        props: {
          isPaper: true,
        },
        fields: [
          addressField,
          accessField,
          typeField,
          categoriesField,
        ],
      },
      {
        type: 'column',
        title: translate('hubstr.event.form.titles.registrations'),
        props: {
          isPaper: true,
          titleType: 'heading',
        },
        fields: [
          statusField,
          registrationDateStartField,
          isRegistrationOpenedField,
          residentsLimitField,
        ],
      },
      {
        type: 'column',
        props: {
          isPaper: true,
        },
        fields: [
          invitedDepartmentsRequiredField,
          reportField,
          broadcastLinkField,
          {
            type: 'select',
            name: 'poll',
            dataType: 'array',
            options: [],
            label: translate('hubstr.event.form.poll.label'),
            props: {
              placeholder: translate('hubstr.event.form.poll.placeholder'),
              valueKey: 'id',
              labelKey: 'name',
              filterable: true,
              remote: true,
              remoteMethod: getPollList,
              disabled: (data: { state: {isPublished: boolean} }) => data.state.isPublished,
            },
          },
          {
            type: 'custom',
            name: 'pollLink',
            component: () => PollLink,
            props: {},
          },
          {
            type: 'input',
            name: 'isPublished',
            props: {
              disabled: true,
              hidden: true,
            },
          },
        ],
      },
    ],
  },
];
