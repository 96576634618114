<template>
  <div class="phone-body interview-preview-body">
    <template v-for="item in step.questions" :key="item.uuid">
      <div 
        v-if="item.question"
        class="phone-body__section"
      >
        <h3 class="phone-body__title">
          {{ item.question }}

          <span v-if="item.required">*</span>
          <span v-if="item.inZeroCondition">{{ item.required ? '*' : '**' }}</span>
        </h3>

        <div class="phone-body__subtitle">{{ item.description }}</div>

        <div class="phone-body__content">
          <field-textarea 
            v-if="item.type === 'type_plain_answer' 
              && item.dataType === 'type_string' 
              && (item as IPlainAnswerQuestion).textLength === 'long'
            "
            v-model="item.value" 
            long
          />

          <field-input 
            v-else-if="item.type === 'type_plain_answer' 
              && item.dataType === 'type_string'
              && (item as IPlainAnswerQuestion).textLength !== 'long'
            "
            v-model="item.value" 
          /> 

          <field-number 
            v-else-if="item.type === 'type_plain_answer' && item.dataType === 'type_int'"
            v-model="item.value"  
            :max="(item as IPlainAnswerQuestion).intLimit || (item as IPlainAnswerQuestion).totalLimit || 'Infinity'" 
            :min="0"
          />

          <field-questions 
            v-else-if="
              item.type === 'type_list' && 
                item.dataType === 'type_string' && 
                (item as IListQuestion)?.multiselect
            "
            v-model="item.value" 
            :options="(item as IListQuestion).options || []"  
            :multiple="true"
            :number="false"
            :answer="(item as IListQuestion).selfAnswer"
          />

          <field-questions 
            v-else-if="item.type === 'type_list'"
            v-model="item.value" 
            :options="(item as IListQuestion).options || []"  
            :multiple="false"
            :number="item.dataType !== 'type_string'"
            :answer="(item as IListQuestion).selfAnswer"
            :max="((item as IListQuestion).totalLimit || 0) ?? 'Infinity'"
            :min="0"
          />

          <field-file
            v-else-if="item.type === 'type_file'"
            v-model="item.value" 
          />

          <field-rate 
            v-else-if="item.type === 'type_stars'"
            v-model="item.value" 
            :max="(item as IStarsQuestion).intLimit || 10" 
          />

          <field-date
            v-else-if="item.type === 'type_date' && item.dataType === 'type_datetime'"
            v-model="item.value" 
          />

          <field-time 
            v-else-if="item.type === 'type_date' && item.dataType === 'type_time'"
            v-model="item.value" 
          />
        </div>
      </div>
    </template>
  </div>
</template>
    
<script lang="ts">
import { PropType, defineComponent } from 'vue';

import type { IStep, IPlainAnswerQuestion, IListQuestion, IStarsQuestion } from 'features/settings/interviews/interfaces';

import FieldQuestions from './fields/field-questions.vue';
import FieldTextarea from './fields/field-textarea.vue';
import FieldInput from './fields/field-input.vue';
import FieldNumber from './fields/field-number.vue';
import FieldFile from './fields/field-file.vue';
import FieldRate from './fields/field-rate.vue';
import FieldDate from './fields/field-date.vue';
import FieldTime from './fields/field-time.vue';

export default defineComponent({
  name: 'IntreviewPreviewBody',

  components: {
    FieldQuestions,
    FieldInput,
    FieldNumber,
    FieldTextarea,
    FieldFile,
    FieldRate,
    FieldDate,
    FieldTime,
  },

  props: {
    step: {
      type: Object as PropType<IStep>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.phone-body {
  flex-grow: 2;
  overflow-y: auto;
  overflow-x: hidden;

  &__section {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-top: 40px;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    padding-right: 16px;

    span {
      color: rgba(255, 55, 91, 1);
    }
  }

  &__subtitle {
    color: rgba(151, 151, 151, 1);
    font-size: 14px;
    line-height: 18px;
    margin-top: 8px;
  }

  &__content {
    margin-top: 24px;
  }
}
</style>

<style lang="scss">
.interview-preview-body {
  span, i {
    position: initial;
    display: initial;
    color: initial;
  }

  i {
    transform: initial;
    height: initial;
    width: auto;
    background: initial;
    border: initial;
    box-shadow: initial;
    left: 0;
  }
}
</style>
