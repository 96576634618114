<template>
  <el-card>
    <template v-for="conditionBlock in state.blocks" :key="conditionBlock.id">
      <div class="condition__block">
        <template v-for="condition in conditionBlock.conditions" :key="condition.serial">
          <el-row gutter="10" class="condition__row">
            <el-col :span="8">
              <el-select
                v-model="condition.parentQuestion"
                value-key="uuid"
                :placeholder="translateText('gaz.interview.components.condition.question_select.placeholder')"
                :disabled="onlyMeta"
                @change="onQuestionSelect($event, condition)"
              >
                <el-option-group
                  v-for="step in state.steps"
                  :key="step.uuid"
                  :label="`${translateText('gaz.interview.components'
                    + '.condition.question_select.step_label')} ${step.serial}`"
                >
                  <el-option
                    v-for="question in step.questions"
                    :key="question.uuid"
                    :value="question"
                    :label="`${translateText('gaz.interview.components'
                      + '.condition.question_select.question_label')} ${question.stepSerial}`"
                  />
                </el-option-group>
              </el-select>
            </el-col>
            <el-col :span="7">
              <template
                v-if="
                  condition.parentQuestion?.type === QUESTION_TYPE.PLAIN_ANSWER
                    || condition.parentQuestion?.type === QUESTION_TYPE.STARS
                    || condition.parentQuestion?.type === QUESTION_TYPE.FILE
                    || condition.parentQuestion?.type === QUESTION_TYPE.DATE"
              >
                <el-select v-model="condition.operator" :disabled="onlyMeta" @change="onDataUpdated">
                  <el-option
                    v-for="operator in getOperatorList(condition)"
                    :key="operator.id"
                    :label="operator.title"
                    :value="operator.id"
                  />
                </el-select>
              </template>

              <template v-if="condition.parentQuestion?.type === QUESTION_TYPE.LIST">
                <el-select v-model="condition.value" :disabled="onlyMeta" @change="onDataUpdated">
                  <el-option
                    v-for="option in condition.parentQuestion.options"
                    :key="option.uuid"
                    :label="option.value"
                    :value="option.uuid"
                  />
                </el-select>
              </template>
            </el-col>
            <el-col :span="7">
              <template v-if="showValueInput(condition)">
                <el-input-number
                  v-model="condition.value"
                  :disabled="onlyMeta"
                  @change="onDataUpdated"
                />
              </template>
            </el-col>
            <el-col :span="2" style="text-align: right">
              <el-button
                type="danger"
                plain
                :icon="Delete"
                style="width: auto; margin: 0"
                :disabled="onlyMeta"
                @click="deleteCondition(condition.id, conditionBlock.id)"
              />
            </el-col>
          </el-row>
        </template>
        <el-button
          v-if="!onlyMeta"
          :icon="Plus"
          size="small"
          round
          style="width: 90px; margin: 0"
          @click="addCondition(conditionBlock.id)"
        >
          {{ translateText('gaz.interview.components'
            + '.condition.add_condition_btn') }}
        </el-button>
      </div>
    </template>
    <el-button
      v-if="!onlyMeta"
      :icon="Plus"
      size="small"
      round
      style="width: 70px; margin: 0"
      @click="addConditionBlock"
    >
      {{ translateText('gaz.interview.components'
        + '.condition.add_block_btn') }}
    </el-button>
  </el-card>
</template>

<script lang="ts">
import {
  Plus,
  Delete,
} from '@element-plus/icons-vue';
import {
  defineComponent, onMounted, PropType, reactive, watch,
} from 'vue';
import { v4 as uuidv4 } from 'uuid';
import {
  IStep,
  CONDITION_OPERATORS, ICondition,
  IConditionBlock, IPlainAnswerQuestion,
  IQuestionType,
} from 'features/settings/interviews/interfaces';
import { translate, magnerConfirm, useTranslate } from 'magner';
import { DATA_TYPE, QUESTION_TYPE } from 'features/settings/interviews/constants';

export default defineComponent({
  name: 'ConditionCard',
  props: {
    conditionsData: {
      type: Array as PropType<IConditionBlock[]>,
      required: true,
    },
    conditionEntityUuid: {
      type: String,
      required: true,
    },
    entityType: {
      type: String as PropType<'step' | 'question'>,
      default: 'step',
    },
    steps: {
      type: Array as PropType<IStep[]>,
      required: true,
    },
    onlyMeta: Boolean,
  },
  emits: ['update:conditionsData'],
  setup (props, { emit }) {
    const { customT } = useTranslate();
    /**
     * Список условий для выбранного вопроса
     */
    const state: {blocks: IConditionBlock[], steps: IStep[]} = reactive({
      blocks: [],
      steps: [],
    });
    /**
     * Метод проходит по всем кондишинам и обновляет данные выбранных вопросов
     */
    const updateState = () => {
      state.steps = [...props.steps];
      state.blocks = [...(props.conditionsData as unknown as ICondition[][])
        .map((b: ICondition[], blockIndex: number) => ({
          id: uuidv4(),
          serial: blockIndex + 1,
          conditions: [...b],
        }))];
      //
      state.blocks.map((b: IConditionBlock) => {
        b.conditions.map((c: ICondition) => {
          state.steps.map((s: IStep) => {
            s.questions.map((q: IQuestionType) => {
              if (c.parentQuestionUuid === q.uuid) {
                c.parentQuestion = { ...q };
              }

              return q;
            });

            return s;
          });

          return c;
        });
        return b;
      });
    };
    /**
     * для данных типа "строка" оператор выбирается только в случае, если поле не обязательное
     */
    const STRING_OPERATORS = [{
      id: CONDITION_OPERATORS.NOT_EMPTY,
      title: 'Не пустое',
    }, {
      id: CONDITION_OPERATORS.EMPTY,
      title: 'Пустое',
    }];
    /**
     * для данных типа "строка" оператор выбирается только в случае, если поле обязательное
     */
    const STRING_OPERATORS_REQUIRED = [{
      id: CONDITION_OPERATORS.NOT_EMPTY,
      title: 'Не пустое',
    }];
    /**
     * для данных типа "число" оператор выбирается только в случае, если поле не обязательное
     */
    const NUMBER_OPERATORS = [{
      id: CONDITION_OPERATORS.NOT_EMPTY,
      title: 'Не пустое',
    }, {
      id: CONDITION_OPERATORS.EMPTY,
      title: 'Пустое',
    }, {
      id: CONDITION_OPERATORS.EQUALS,
      title: 'Равно',
    }, {
      id: CONDITION_OPERATORS.GREATER,
      title: 'Больше',
    }, {
      id: CONDITION_OPERATORS.LESS,
      title: 'Меньше',
    }];
    /**
     * для данных типа "число" оператор выбирается только в случае, если поле обязательное
     */
    const NUMBER_OPERATORS_REQUIRED = [{
      id: CONDITION_OPERATORS.NOT_EMPTY,
      title: 'Не пустое',
    }, {
      id: CONDITION_OPERATORS.EQUALS,
      title: 'Равно',
    }, {
      id: CONDITION_OPERATORS.GREATER,
      title: 'Больше',
    }, {
      id: CONDITION_OPERATORS.LESS,
      title: 'Меньше',
    }];
    /**
     *
     */
    const onDataUpdated = () => {
      /**
       * Перед тем как отдать данные "наружу" - преобразовываем их в двумерный массив
       */
      const out: ICondition[][] = [];
      state.blocks.map((b: IConditionBlock) => {
        out.push(b.conditions);
        return b;
      });
      emit('update:conditionsData', out);
    };
    /**
     * Метод обрабатывает выбор вопроса
     */
    const onQuestionSelect = (value: IQuestionType, condition: ICondition) => {
      /**
       * тип - оценка или простой вопрос с типом данных "число"
       */
      if (
        (
          value.type === QUESTION_TYPE.PLAIN_ANSWER
          && (value as IPlainAnswerQuestion).dataType === DATA_TYPE.NUMBER
        )
        || value.type === QUESTION_TYPE.STARS
      ) {
        condition.operator = NUMBER_OPERATORS[0].id;
      }
      /**
       * тип - список
       */
      if (value.type === QUESTION_TYPE.LIST) {
        condition.operator = CONDITION_OPERATORS.OPTION;
      }
      /**
       * тип - загрузка файла, дата или простой вопрос с типом данных "строка"
       */
      if (
        (
          value.type === QUESTION_TYPE.PLAIN_ANSWER
          && (value as IPlainAnswerQuestion).dataType === DATA_TYPE.STRING
        )
        || value.type === QUESTION_TYPE.FILE
        || value.type === QUESTION_TYPE.DATE
      ) {
        condition.operator = STRING_OPERATORS[0].id;
      }

      condition.parentQuestionUuid = value.uuid;
      condition.value = '';

      onDataUpdated();
    };
    /**
     * Метод отдает список операторов, основываясь на выбранном вопросе
     */
    const getOperatorList = (condition: ICondition) => {
      /**
       * если тип вопроса "оценка" или "простой вопрос" с типом данных "число"
       */
      if (
        (
          condition.parentQuestion.type === QUESTION_TYPE.PLAIN_ANSWER
          && condition.parentQuestion.dataType === DATA_TYPE.NUMBER
        )
        || condition.parentQuestion.type === QUESTION_TYPE.STARS
      ) {
        return condition.parentQuestion.required ? NUMBER_OPERATORS_REQUIRED : NUMBER_OPERATORS;
      }
      /**
       * если тип вопроса "дата", "загрузка файла" или "простой вопрос" с типом данных "строка"
       */
      if (
        (
          condition.parentQuestion.type === QUESTION_TYPE.PLAIN_ANSWER
          && condition.parentQuestion.dataType === DATA_TYPE.STRING
        )
        || condition.parentQuestion.type === QUESTION_TYPE.FILE
        || condition.parentQuestion.type === QUESTION_TYPE.DATE
      ) {
        return condition.parentQuestion.required ? STRING_OPERATORS_REQUIRED : STRING_OPERATORS;
      }

      return [];
    };
    /**
     * Метод решает отображать или нет поле ввода параметра для сравнения
     * @param condition
     */
    const showValueInput = (condition: ICondition) => {
      if (condition.parentQuestion?.type === QUESTION_TYPE.PLAIN_ANSWER) {
        return condition.parentQuestion?.dataType === DATA_TYPE.NUMBER
          && !STRING_OPERATORS.map((s) => s.id).includes(condition.operator);
      }

      return condition.parentQuestion?.type === QUESTION_TYPE.STARS
        && !STRING_OPERATORS.map((s) => s.id).includes(condition.operator);
    };
    /**
     * Метод добавляет новое условие в указанный блок
     * @param conditionBlockId
     */
    const addCondition = (conditionBlockId: string) => {
      state.blocks.map((block: IConditionBlock) => {
        if (block.id === conditionBlockId) {
          block.conditions.push({
            id: uuidv4(),
            serial: block.conditions.length + 1,
            parentQuestionUuid: '',
            operator: '',
            value: '',
          });
        }

        return block;
      });

      onDataUpdated();
    };
    /**
     * Метод добавляет блок условий
     */
    const addConditionBlock = () => {
      state.blocks.push({
        id: uuidv4(),
        serial: state.blocks.length + 1,
        conditions: [{
          id: uuidv4(),
          serial: 1,
          parentQuestionUuid: '',
          operator: '',
          value: '',
        }],
      });

      onDataUpdated();
    };
    /**
     * Метод удаляет условие из блока
     * @param conditionId
     * @param conditionBlockId
     */
    const deleteCondition = (conditionId: string, conditionBlockId: string) => {
      magnerConfirm({
        message: customT(translate('gaz.interview.components.condition.confirm.message')),
        title: customT(translate('gaz.interview.components.condition.confirm.title')),
        confirmButtonText: customT(translate('gaz.interview.components.condition.confirm.confirm')),
        cancelButtonText: customT(translate('gaz.interview.components.condition.confirm.cancel')),
        type: 'warning',
      }).then(() => {
        state.blocks.map((b: IConditionBlock) => {
          if (b.id === conditionBlockId) {
            b.conditions = [...b.conditions.filter((c: ICondition) => c.id !== conditionId)];
            return b;
          }
          return b;
        });
        //
        // почистим список от блоков без условий
        //
        state.blocks = [...state.blocks.filter((b: IConditionBlock) => b.conditions.length > 0)];

        onDataUpdated();
      });
    };
    /**
     *
     */
    onMounted(() => updateState());
    watch(() => props.steps, () => updateState());

    return {
      state,

      STRING_OPERATORS,
      STRING_OPERATORS_REQUIRED,
      NUMBER_OPERATORS,

      QUESTION_TYPE,
      DATA_TYPE,

      onQuestionSelect,
      getOperatorList,
      showValueInput,
      addCondition,
      addConditionBlock,
      deleteCondition,
      onDataUpdated,
      translateText: (code: string) => customT(translate(code)),

      Plus,
      Delete,
    };
  },
});
</script>

<style lang="scss" src="/src/features/settings/interviews/assets/scss/condition.scss" scoped/>
