import OrganizerSelector from 'features/events/organizer-selector/organizer-selector.vue';
import { addressSearch } from 'features/addresses/requests/hubstr';
import { eventCategoriesSearch } from 'features/settings/event-categories/requests/hubstr';
import { autocompleteDepartment } from 'features/residents/requests/hubstr';
import { translate } from 'magner';
import dayjs from 'dayjs';
import MarkdownEditor from 'features/events/components/markdown-editor/markdown-editor.vue';
import { routeTransform } from '~/utils/route-transform';
import { APP_ETALON, SUB_DOMEN } from '~/constants';

export const nameField = {
  type: 'input',
  name: 'name',
  label: translate('hubstr.event.form.name.label'),
  hint: translate('hubstr.event.form.name.hint'),
  props: {
    required: true,
    placeholder: translate('hubstr.event.form.name.placeholder'),
  },
  validation: [
    {
      type: 'empty-required' as 'empty',
      trigger: 'change',
    },
    {
      type: 'max-length-128' as 'empty',
      trigger: 'change',
    },
  ],
};

export const dateStartField = {
  type: 'datetime',
  name: 'dateStart',
  dataType: 'array',
  label: translate('hubstr.event.form.date.label'),
  props: {
    required: true,
    type: 'datetimerange',
    format: 'DD.MM.YYYY HH:mm',
    startPlaceholder: translate('hubstr.event.form.date.start_placeholder'),
    endPlaceholder: translate('hubstr.event.form.date.end_placeholder'),
    defaultTime: [dayjs(new Date()).tz('Europe/Moscow').format('YYYY-MM-DDTHH:mm:ss'), dayjs(new Date()).tz('Europe/Moscow').format('YYYY-MM-DDTHH:mm:ss')],
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'blur',
  }],
};

export const registrationDateStartField = {
  type: 'datetime',
  name: 'registrationDateStart',
  dataType: 'array',
  label: translate('hubstr.event.form.registration_date.label'),
  props: {
    required: true,
    type: 'datetimerange',
    format: 'DD.MM.YYYY HH:mm',
    startPlaceholder: translate('hubstr.event.form.date.start_placeholder'),
    endPlaceholder: translate('hubstr.event.form.date.end_placeholder'),
    defaultTime: [dayjs(new Date()).tz('Europe/Moscow').format('YYYY-MM-DDTHH:mm:ss'), dayjs(new Date()).tz('Europe/Moscow').format('YYYY-MM-DDTHH:mm:ss')],
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'blur',
  }],
};

export const organizersField = {
  type: 'custom',
  name: 'organizers',
  dataType: 'array',
  component: () => OrganizerSelector,
  label: translate('hubstr.event.form.organizer.label'),
  props: {
    required: true,
    placeholder: translate('hubstr.event.form.organizer.placeholder'),
    disabled: (data: { state: {isPublished: boolean} }) => !!data.state.isPublished,
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const descriptionField = {
  type: 'custom',
  name: 'description',
  label: translate('hubstr.event.form.description.label'),
  component: () => MarkdownEditor,
  props: {
    type: 'textarea',
    required: true,
    props: {
      rows: 10,
      placeholder: translate('hubstr.event.form.description.placeholder'),
    },
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const logoField = {
  type: 'dropzone',
  name: 'logo',
  label: translate('hubstr.event.form.image.label'),
  hint: translate('hubstr.event.form.image.hint'),
  props: {
    formats: ['jpg', 'png', 'jpeg', 'heic'],
    required: true,
    removable: true,
    valueKey: 'id',
    srcKey: 'image',
    maxSize: 8,
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'blur',
  }],
};

export const addressField = {
  type: 'select',
  name: 'address',
  label: translate('hubstr.event.form.address.label'),
  hint: 'Чтобы выбрать адрес из списка его нужно создать в <a href=/catalogs/addresses>справочнике</a>.',
  options: [],
  props: {
    required: true,
    filterable: true,
    remote: true,
    remoteMethod: addressSearch,
    valueKey: 'id',
    labelKey: 'title',
    placeholder: translate('hubstr.event.form.address.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const accessField = {
  type: 'select',
  name: 'access',
  label: translate('hubstr.event.form.access.label'),
  options: [{
    label: translate('hubstr.event.form.access.opt_closed'),
    value: 'closed',
  }, {
    label: translate('hubstr.event.form.access.opt_half_open'),
    value: 'halfOpen',
  }, {
    label: translate('hubstr.event.form.access.opt_open'),
    value: 'open',
  }, {
    label: translate('hubstr.event.form.access.opt_family'),
    value: 'family',
  }, {
    label: translate('hubstr.event.form.access.opt_external'),
    value: 'external',
  }],
  props: {
    required: true,
    placeholder: translate('hubstr.event.form.access.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const typeField = {
  type: 'select',
  name: 'type',
  label: translate('hubstr.event.form.type.label'),
  options: [{
    label: translate('hubstr.event.form.type.opt_main'),
    value: 'main',
  }, {
    label: translate('hubstr.event.form.type.opt_additional'),
    value: 'additional',
  }, {
    label: translate('hubstr.event.form.type.opt_clubInClub'),
    value: 'clubInClub',
  }, {
    label: translate('hubstr.event.form.type.opt_open'),
    value: 'open',
  }],
  props: {
    required: true,
    placeholder: translate('hubstr.event.form.type.placeholder'),
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const categoriesField = {
  type: 'select',
  name: 'categories',
  dataType: 'array',
  hint: 'Для создания доп. категорий можно перейти по <a href="/catalogs/event-categories">ссылке</a>',
  label: translate('hubstr.event.form.categories.label'),
  options: [],
  props: {
    required: true,
    multiple: true,
    placeholder: translate('hubstr.event.form.categories.placeholder'),
    filterable: true,
    remote: true,
    valueKey: 'id',
    labelKey: 'name',
    remoteMethod: eventCategoriesSearch,
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const statusField = {
  type: 'select',
  name: 'status',
  label: translate('hubstr.event.form.status.label'),
  hint: translate('hubstr.event.form.status.hint'),
  options: [{
    label: translate('hubstr.event.form.status.opt_planned'),
    value: 'planned',
  }, {
    label: translate('hubstr.event.form.status.opt_canceled'),
    value: 'canceled',
  }, {
    label: translate('hubstr.event.form.status.opt_closed'),
    value: 'registrationClosed',
  }],
  props: {
    required: true,
    placeholder: translate('hubstr.event.form.status.placeholder'),
    disabled: (data: { state: {isRegistrationOpened: boolean} }) => data.state.isRegistrationOpened !== '' && !data.state.isRegistrationOpened,
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const isRegistrationOpenedField = {
  type: 'radio',
  name: 'isRegistrationOpened',
  label: translate('hubstr.event.form.isRegistrationOpen.label'),
  hint: translate('hubstr.event.form.isRegistrationOpen.hint'),
  options: [{
    label: translate('hubstr.event.form.isRegistrationOpen.opt_main'),
    value: true,
  }, {
    label: translate('hubstr.event.form.isRegistrationOpen.opt_additional'),
    value: false,
  }],
  props: {
    required: true,
    radioButtons: true,
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const residentsLimitField = {
  type: 'input',
  name: 'residentsLimit',
  dataType: 'number',
  label: translate('hubstr.event.form.residentsLimit.label'),
  props: {
    placeholder: translate('hubstr.event.form.residentsLimit.placeholder'),
    mask: {
      mask: '#*',
    },
  },
};

export const invitedDepartmentsField = {
  type: 'select',
  name: 'invitedDepartments',
  dataType: 'array',
  options: [],
  label: translate('hubstr.event.form.department.label'),
  props: {
    required: true,
    placeholder: translate('hubstr.event.form.department.placeholder'),
    valueKey: 'id',
    labelKey: 'name',
    filterable: true,
    remote: true,
    multiple: true,
    remoteMethod: autocompleteDepartment,
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const invitedDepartmentsNotRequiredField = {
  type: 'select',
  name: 'invitedDepartments',
  dataType: 'array',
  options: [],
  label: translate('hubstr.event.form.department.label'),
  props: {
    placeholder: translate('hubstr.event.form.department.placeholder'),
    valueKey: 'id',
    labelKey: 'name',
    filterable: true,
    remote: true,
    multiple: true,
    remoteMethod: autocompleteDepartment,
  },
};

export const invitedDepartmentsRequiredField = {
  type: 'select',
  name: 'invitedDepartments',
  dataType: 'array',
  options: [],
  label: translate('hubstr.event.form.department.label'),
  props: {
    required: true,
    placeholder: translate('hubstr.event.form.department.placeholder'),
    valueKey: 'id',
    labelKey: 'name',
    filterable: true,
    remote: true,
    multiple: true,
    remoteMethod: autocompleteDepartment,
    hidden: SUB_DOMEN !== APP_ETALON,
  },
  validation: [{
    type: 'empty-required' as 'empty',
    trigger: 'change',
  }],
};

export const reportField = {
  type: 'textarea',
  name: 'report',
  label: translate('hubstr.event.form.report.label'),
  props: {
    rows: 3,
    placeholder: translate('hubstr.event.form.report.placeholder'),
  },
};

export const broadcastLinkField = {
  type: 'input',
  name: 'broadcastLink',
  label: translate('hubstr.event.form.broadcast_link.label'),
  props: {
    placeholder: translate('hubstr.event.form.broadcast_link.placeholder'),
  },
  validation: [{
    type: 'url' as 'empty',
    trigger: 'change',
  }],
};

export const materialsLinkField = {
  type: 'input',
  name: 'eventMaterialsLink',
  label: translate('hubstr.event.form.materials_link.label'),
  props: {
    placeholder: translate('hubstr.event.form.materials_link.placeholder'),
  },
  validation: [{
    type: 'url' as 'empty',
    trigger: 'change',
  }],
};
