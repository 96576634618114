import { developmentController } from 'magner';
import profileRequest from 'configs/development/profile';
import logoutRequest from 'configs/development/logout';
import validation from 'configs/development/validation';
import { API_URL } from '~/constants';
import { appVersion } from '~/utils/app-version-checker';

const development = developmentController({
  envs: {
    API_URL,
  },
  noBackendMode: false,
  toggleBtnPositionTop: true,
  profileRequest,
  logoutRequest,
  validation,
  appVersion: await appVersion(),
});

export default development;
