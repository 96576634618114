import { translate, filtersFormController } from 'magner';
import type { Event } from 'features/events/types/hubstr';
import { eventCategoriesSearch } from 'features/settings/event-categories/requests/hubstr';

const eventsFilters = filtersFormController<Event>({
  actions: [],

  saveToLocalStorage: true,

  submitEvent: 'input',

  pagination: {
    items: 25,
    page: 1,
  },

  filtersInSeparatePanel: true,
  filtersData: {
    name: '',
    status: false,
    dateStart: null,
    categories: null,
  },
  sort: {},

  layout: [
    {
      type: 'input',
      name: 'name',
      props: {
        placeholder: translate('hubstr.event.filters.name'),
        inputDelay: 250,
      },
    },
    {
      type: 'select',
      name: 'categories',
      dataType: 'array',
      options: [],
      props: {
        multiple: true,
        filterable: true,
        remote: true,
        collapseTags: true,
        valueKey: 'id',
        labelKey: 'name',
        remoteMethod: eventCategoriesSearch,
        placeholder: translate('hubstr.event.filters.categories'),
      },
    },
    {
      type: 'datetime',
      name: 'dateStart',
      dataType: 'date',
      props: {
        type: 'month',
        format: 'MM.YYYY',
        placeholder: translate('hubstr.event.filters.date'),
      },
    },
    {
      type: 'switch',
      name: 'status',
      dataType: 'boolean',
      props: {
        activeLabel: translate('hubstr.event.filters.planned_only'),
      },
    },
  ],
});

export default eventsFilters;
