import {
  action,
  cardPageController, magnerConfirm,
  translate,
} from 'magner';
import {
  eventCreate,
  eventDelete,
  getEventReportRating,
  eventGet,
  eventUpdate,
  publishEvent,
} from 'features/events/requests/hubstr';
import type { EventCreate } from 'features/events/types/hubstr';
import { gazpromLayout } from 'features/events/layouts/gazprom';

const eventConfig = cardPageController<EventCreate>({
  header: {
    title: translate('hubstr.event.form.title'),
    tabs: [
      {
        label: translate('hubstr.event.tabs.form'),
        active: true,
        link: { name: 'event' },
      },
      {
        label: translate('hubstr.event.tabs.guests'),
        active: false,
        link: { name: 'event-guests' },
      },
    ],
  },

  getRequest: eventGet,
  createRequest: eventCreate,
  updateRequest: eventUpdate,
  deleteRequest: eventDelete,

  confirmDelete: true,

  form: {
    actions: [
      {
        type: 'action',
        emits: 'cancel',
        props: {
          text: translate('hubstr.event.form.cancel_button'),
          class: 'cancel',
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: translate('hubstr.event.form.submit_button'),
          type: 'primary',
          class: 'submit',
          // hidden: ({ state, isNew }) => !isNew && !!state.isPublished,
        },
      },
      {
        type: 'action',
        emits: 'submit_publish' as 'submit',
        props: {
          text: translate('hubstr.event.form.submit_button_publish'),
          type: 'success',
          class: 'submit_publish',
          hidden: ({ state, isNew }) => isNew || !!state.isPublished,
        },
        // @ts-ignore
        action: publishEvent,
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          text: translate('hubstr.event.form.remove_button'),
          type: 'danger',
          class: 'remove',
        },
        action: async ({ data } : any): Promise<any> => {
          if (data.form.isPublished && data.form.pollLink !== '') {
            await magnerConfirm({
              title: 'Внимание!',
              message: 'Нельзя удалить, потому что мероприятие опубликовано и прикреплен опрос.',
              showConfirmButton: false,
              type: 'error',
            });
          }
        },
      },

      {
        type: 'action',
        emits: 'download_xlsx' as 'submit',
        props: {
          text: translate('hubstr.event.form.rating_statistics.xls'),
          type: 'primary',
          class: 'xls statistics',
        },
        // @ts-ignore
        action: action(async ({ data }) => {
          try {
            // @ts-ignore
            await getEventReportRating({ ...data, format: 'xlsx' });
          } catch (e) {
            console.log(e);
          }
        }),
      },

      {
        type: 'action',
        emits: 'download_csv' as 'submit',
        props: {
          text: translate('hubstr.event.form.rating_statistics.csv'),
          type: 'primary',
          class: 'csv statistics',
        },
        // @ts-ignore
        action: action(async ({ data }) => {
          try {
            // @ts-ignore
            await getEventReportRating({ ...data, format: 'csv' });
          } catch (e) {
            console.log(e);
          }
        }),
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: gazpromLayout as any,
    },
  },
});

export default eventConfig;
